const $ = require('jquery');
global.$ = global.jQuery = $;
require('bootstrap');
require('popper.js');
require('select2');
require('bootstrap-autocomplete');
require('bootstrap-icons/font/bootstrap-icons.css');
require('../css/app.scss');

$(document).ready(function () {

    // Always focus on first input text field.
    $('form:first *:input[type=text]:not(.resize-on-focus):first').focus();

    // Enable custom select boxes.
    $('.select2').select2();
    $('.select2.department').select2({
        placeholder: "Vælg afdeling(er)",
        allowClear: true
    });

    if ($('#search-workers .type').val() == '-1') {
        $('#search-workers #from_date').attr('disabled', true);
        $('#search-workers #to_date').attr('disabled', true);
    }

    $('.worker-submit').on('click', function (e) {
        let returnPath = window.location.href;
        let doAjax = true;
        let fields = $('*[name^="' + $(this).data('id') + '"]');
        let dataObject = {};

        for (let i = 0; i < fields.length; i++) {
            let key = fields[i].name.match(/\[(\w+)\]/);

            if ('select' === fields[i].nodeName.toLowerCase()) {
                dataObject[key[1]] = fields[i].options[fields[i].selectedIndex].value;
            } else {
                if ('checkbox' === fields[i].type.toLowerCase()) {
                    dataObject[key[1]] = fields[i].checked;
                } else {
                    dataObject[key[1]] = fields[i].value.trim();
                }
            }
        }

        if ((dataObject.injob === true && dataObject.jobdate === '') || (dataObject.injob === false && dataObject.jobdate !== '')) {
            doAjax = false;
            alert('Kan ikke gemme, både "Fået job" og "Dato for job" skal være sat.');
        }

        if (doAjax) {
            fetch("/worker/update_worker/" + $(this).data('id'), {
                method: 'POST',
                body: JSON.stringify(dataObject),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then(response => response.json())
                .then(response => {
                    window.location.reload(true);
                })
                .catch(function (error) {
                });
        }

        e.preventDefault();
    });

    // Enable tooltips on the site.
    $('[data-toggle="tooltip"]').tooltip();

    $('.autocomplete').autoComplete({
        minLength: 2,
        formatResult: function (item) {
            return {
                value: item.id,
                text: '#' + item.wid + ':  ' + item.text,
                html: [
                    $('<div/>', {
                        html: '#' + item.wid + ': ' + item.text,
                        'data-id': item.id,
                        'data-name': item.text,
                        'data-wid': item.wid
                    })
                ]
            }
        },
    });

    $(document).on("keypress", ".task-search-youth input.autocomplete", function (e) {
        var code = e.keyCode || e.which;
        if (code == 13) {
            $('#button-addon1').trigger('click');
            e.preventDefault();
            return false;
        }
    });

    $('.autocomplete').on('autocomplete.select', function (e, item) {
        $('#button-addon1').trigger('click');
        e.preventDefault();
    });

    $('#search-workers .type').on("change", function (e) {
        if ($(this).val() != -1) {
            $('#search-workers #from_date').attr('disabled', false);
            $('#search-workers #to_date').attr('disabled', false);
        } else {
            $('#search-workers #from_date').attr('disabled', true);
            $('#search-workers #to_date').attr('disabled', true);
        }
    });
    $('.add-to-list').on('click', function () {
        if ($('.dropdown-menu .active div').data('id') != undefined) {
            var name = $('.dropdown-menu .active div').data('name');
            var id = $('.dropdown-menu .active div').data('id');
            var wid = $('.dropdown-menu .active div').data('wid');

            var ok = true;
            $('.youth-workers .input-group').each(function (index) {
                if ($(this).data('workerid') == id) {
                    alert(name + ' er allerede i listen.');
                    ok = false;
                }
            });

            $('.dropdown-menu .dropdown-item').remove();
            $('.autocomplete').val('');

            if (ok === false) return;
            var elem = '<div class="input-group mb-2" data-workerid="' + id + '">' +
                '<div class="input-group-prepend">' +
                '<span style="width: 300px;" class="input-group-text"><code><a href="/worker/' + id + '/view">#' + wid + '</a></code>&nbsp;' + name + '</span>' +
                '</div>' +
                '<input type="number" name="hour[' + id + ']" step="0.25" class="text-right pr-2 form-control" min="0" autocomplete="off" id="hour" value="0">' +
                '<div class="input-group-append">' +
                '<span class="input-group-text">Timer</span>' +
                '</div>' +
                '</div>';
            $('.youth-workers').append(elem);
        }
    });
    // Toggle group manager pay rate form field visibility.
    if ($('#worker_group_manager:checked').length > 0) {
        $('.gm-payrate').show();
        $('.gm-type').show();
        $('.gm-certificate').show();
        $('.gm-license').show();
    }
    if ($('#worker_certificate').is(':checked')) {
        $('.gm-certificate-date').show();
    }
    $('#worker_certificate').on('change', function () {
        $('.gm-certificate-date').toggle();
    });
    $('#worker_group_manager').on('change', function () {
        $('.gm-payrate').toggle();
        $('.gm-type').toggle();
        $('.gm-certificate').toggle();
        $('.gm-license').toggle();
    });

    // Toggle job form elements visibility
    if ($('#worker_job:checked').length > 0) {
        $('.job-note').show();
        $('.job-date').show();
        $('.job-giftcard').show();
        $('.job-giftcard-issuer').show();
        $('.job-giftcard-date').show();
    }
    $('#worker_job').on('change', function () {
        $('.job-note').toggle();
        $('.job-date').toggle();
        $('.job-giftcard').toggle();
        $('.job-giftcard-issuer').toggle();
        $('.job-giftcard-date').toggle();
    });

    // Fetch city from zipcode on create youthworker.
    $('#worker_zipcode').blur(function () {
        if ($('#worker_zipcode').val().length == 4) {
            fetch('https://dawa.aws.dk/postnumre/autocomplete?nr=' + $('#worker_zipcode').val()).then(response => {
                response.json().then(json => {
                    let data = json[0];
                    if (typeof data !== 'undefined') {
                        $('#worker_city').val(data.postnummer.navn);
                    } else {
                        $('#worker_city').val('Invalid zipcode entered');
                    }
                });
            });
        }
    });
});
